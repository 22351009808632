<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">模块名称</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="">一级导航 / </router-link>
          <span class="crumbs_item crumbs_last">末级</span>
        </div>
      </div>
      <div class="sider_right">
        <a-button type="primary">按钮</a-button>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="all_content_box">
      <p>左右分布式</p>
      <div class="L_R_inner">
        <span class="all_left_name all_required">功能名称</span>
        <div class="right_Div">
          <a-input class="all_input" placeholder="请输入占位内容" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">功能名称</span>
        <div class="right_Div">
          <a-radio-group name="radioGroup" :default-value="1">
            <a-radio :value="1">
              A
            </a-radio>
            <a-radio :value="2">
              B
            </a-radio>
            <a-radio :value="3">
              C
            </a-radio>
            <a-radio :value="4">
              D
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">功能名称</span>
        <div class="right_Div">
          <div class="all_flex">
            <a-input class="all_input all_margin-r" placeholder="请输入占位内容" />
            <a-input class="all_input all_margin-r" placeholder="请输入占位内容" />
            <a-input class="all_input all_margin-r" placeholder="请输入占位内容" />
          </div>
        </div>
      </div>
     
     <p>上下分布式</p>
     <div class="Up_Down_inner">
        <span class="all_left_name all_required">功能名称</span>
        <div class="bgDiv">
          <p>订单编号：xxxxxxxxxxxxxxxxxxx</p>
          <p>订单编号：xxxxxxxxxxxxxxxxxxx</p>
          <p>订单编号：xxxxxxxxxxxxxxxxxxx</p>
          <p>订单编号：xxxxxxxxxxxxxxxxxxx</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {

  }
}
</script>

<style lang="less" scoped></style>
